/** @format */
import {
  AdjustmentsVerticalIcon,
  ArchiveBoxIcon,
  ArrowsPointingInIcon,
  BanknotesIcon,
  BellIcon,
  BookOpenIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  ClockIcon,
  CodeBracketSquareIcon,
  CogIcon,
  CurrencyDollarIcon,
  HomeIcon,
  IdentificationIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { ENUM_ADMIN_ROLES } from "@prisma/client";
import { usePlatformDetect, useReadCurrentAdmin } from "@roadflex/react-hooks";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Redirect from "../Redirect";
import NavigationOverlay from "./navigation-overlay";
import NavigationUI from "./navigation.ui";

export const navigationList = [
  [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: 1,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
      isMobileResponsive: true,
    },
    {
      name: "Subscribers/Affiliates Program",
      href: "/subscribers",
      icon: ArrowsPointingInIcon,
      current: 2,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.SALES,
        ENUM_ADMIN_ROLES.OPERATIONS,
      ],
      isMobileResponsive: true,
    },
    {
      name: "Applications",
      href: "/onboard",
      icon: ClockIcon,
      current: 3,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.SALES,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
        ENUM_ADMIN_ROLES.UNDERWRITER,
      ],
      isMobileResponsive: false,
    },
    {
      name: "Pending Deposit/Set-up Fee",
      href: "/applications-pending-deposit",
      icon: CurrencyDollarIcon,
      current: 4,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.SALES,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
      isMobileResponsive: true,
    },
    {
      name: "Approved Customers",
      href: "/clients",
      icon: CheckBadgeIcon,
      current: 5,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.UNDERWRITER],
      isMobileResponsive: true,
    },
    {
      name: "Customer Information",
      href: "/customer-dashboard",
      icon: IdentificationIcon,
      current: 6,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
        ENUM_ADMIN_ROLES.OPERATIONS,
      ],
      isMobileResponsive: true,
    },
    {
      name: "Payments",
      href: "/payments",
      icon: BanknotesIcon,
      current: 7,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
      ],
      isMobileResponsive: false,
    },
  ],
  [
    {
      name: "Global Settings",
      href: "/global-settings",
      icon: AdjustmentsVerticalIcon,
      current: 8,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
      isMobileResponsive: false,
    },

    {
      name: "Notifications",
      href: "/notifications",
      icon: BellIcon,
      current: 9,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
      isMobileResponsive: false,
    },
    {
      name: "Blogs",
      href: "/blogs",
      icon: BookOpenIcon,
      current: 10,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.OPERATIONS],
      isMobileResponsive: false,
    },
    {
      name: "Risks",
      href: "/risk",
      icon: RocketLaunchIcon,
      current: 11,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.UNDERWRITER],
      isMobileResponsive: false,
    },
    {
      name: "Collections",
      href: "/collections",
      icon: RectangleStackIcon,
      current: 12,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.COLLECTIONS],
      isMobileResponsive: false,
    },
  ],
  [
    {
      name: "Scripts",
      href: "/scripts",
      icon: CodeBracketSquareIcon,
      current: 13,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
        ENUM_ADMIN_ROLES.OPERATIONS,
      ],
      isMobileResponsive: false,
    },
    {
      name: "Data Analysis",
      href: "/data-analysis",
      icon: ChartBarIcon,
      current: 14,
      canAccess: [
        ENUM_ADMIN_ROLES.SUPERADMIN,
        ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT,
        ENUM_ADMIN_ROLES.OPERATIONS,
      ],
      isMobileResponsive: false,
    },
    {
      name: "Activity Logs",
      href: "/logs",
      icon: ArchiveBoxIcon,
      current: 15,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN, ENUM_ADMIN_ROLES.UNDERWRITER],
      isMobileResponsive: false,
    },
  ],
  [
    {
      name: "Team",
      href: "/team",
      icon: UserGroupIcon,
      current: 16,
      canAccess: [ENUM_ADMIN_ROLES.SUPERADMIN],
      isMobileResponsive: true,
    },
    {
      name: "Account Settings",
      href: "/settings",
      icon: CogIcon,
      current: 17,
      canAccess: [
        ...Object.keys(ENUM_ADMIN_ROLES).map((key) => ENUM_ADMIN_ROLES[key]),
      ],
      isMobileResponsive: true,
    },
  ],
];

/* eslint-disable-next-line */
type NavigationProps = {
  open?: boolean;
  toggle?: () => void;
};

export function Navigation({ open, toggle }: NavigationProps) {
  const [page, setPage] = useState(1);
  const { data, loading } = useReadCurrentAdmin();
  const { isMobile } = usePlatformDetect();
  const [isPropsInitialized, setIsPropsInitialized] = useState(false);

  useEffect(() => {
    // Helper function to check if all required props are initialized
    const propsAreInitialized =
      open !== undefined && typeof toggle === "function";

    if (propsAreInitialized) {
      setIsPropsInitialized(true);
    }
  }, [open, toggle]);

  if (loading || !isPropsInitialized) {
    return <></>;
  }
  const { readCurrentAdmin } = data || {};

  if (isEmpty(readCurrentAdmin)) {
    return <Redirect path="/" />;
  }

  const roles = readCurrentAdmin?.roles || [];
  const navigation = navigationList.map((navBlock) => {
    return navBlock.filter((navItem) => {
      return navItem.canAccess.some((role) => roles.includes(role));
    });
  });

  return (
    <>
      {isMobile() ? (
        <>
          <NavigationOverlay
            open={open}
            toggle={toggle}
            navigation={navigation}
            page={page}
            setPage={setPage}
          />
        </>
      ) : (
        <NavigationUI {...{ navigation, page, setPage }} />
      )}
    </>
  );
}

export default Navigation;
